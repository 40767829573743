import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadCart } from '../actions/cartActions';

const CheckoutButton = ({ itemCount, totalPrice, loadCart }) => {
  useEffect(() => {
    loadCart(); // Dispatch the action to load the cart items
  }, [loadCart]); // Ensure that the effect runs only once when the component mounts

  return (
    <div className="fixed-bottom p-3">
      <Link to={{ pathname: "/checkout" }} className="btn btn-success btn-block btn-lg text-white rounded shadow text-decoration-none d-flex align-items-center shadow">
        <div className="border-right pr-3">
          <h4 className="m-0"><i className="feather-shopping-bag" aria-hidden="true"></i></h4>
        </div>
        <div className="ml-3 text-left">
          <p className="mb-0 small text-white-50">{itemCount} item</p>
          <p className="mb-0 font-weight-bold text-white">TSH {parseFloat(totalPrice).toFixed(2)}</p>
        </div>
        <div className="ml-auto">
          <p className="mb-0 text-white">Proceed to cart <i className="feather-chevron-right pl-2" aria-hidden="true"></i></p>
        </div>
      </Link>
    </div>
  );
};

// Map state to props
const mapStateToProps = (state) => {
  // Calculate the total price by iterating over the items in the cart and summing up the price * quantity for each item
  const totalPrice = state.cart.items.reduce((total, item) => {
    return total + (item.price * item.quantity);
  }, 0);

  return {
    itemCount: state.cart.items.length, // Get the number of items in the cart
    totalPrice: totalPrice.toFixed(2), // Set the total price based on the calculation
  };
};

// Map dispatch to props
const mapDispatchToProps = (dispatch) => {
  return {
    loadCart: () => dispatch(loadCart()) // Dispatch the action to load the cart items
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutButton);
