import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loadCart } from './actions/cartActions'; // Import the loadCart action
import Layout from './Layout';
import CartItem from './Components/CartItem';
import axios from 'axios';
import {environment} from './config/environment';

const wampServer = 'https://asia-south1-dosapoint.cloudfunctions.net/' + 'api';
// const wampServer = 'http://limitless-pos.test/' + 'api';
// const wampServer = 'https://dosapoint.goandroy.com/' + 'api';
// const wampServer = 'http://localhost:8081/';

const api = axios.create({
  baseURL: wampServer,
  // headers: {
  //   // 'Authorization': 'Bearer YOUR_ACCESS_TOKEN', // Example header
  //   'Content-Type': 'application/json', // Example header
  //   'access-control-allow-origin': '*', // Example header
  //   // Add more headers as needed
  // }
});
const CheckoutPage = ({ loadCart }) => {
  const [cart, setCart] = useState([]);
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const history = useNavigate(); // Initialize useHistory hook
  // const [total, setTotal] = useState(0); // State to hold the total

  useEffect(() => {
    // Load the cart items when the component mounts
    const cartData = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(cartData);
    // calculateTotal(cartData);

  }, []); // Empty dependency array to run this effect only once on mount

   // Function to calculate the total
   const calculateTotal = () => {
    let totalAmount = 0;
    cart.forEach(item => {
      totalAmount += item.price * item.quantity;
    });
  return totalAmount
    // setTotal(totalAmount);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construct the data to be sent to the API
    const data = {
     
      sales_date: new Date().toISOString(), // Assuming sales date is current date and time
      user_id: 1,// Assuming you have user ID in authData
      
      warehouse: 1,
      customer_id: 1,
      tax_rate: 0,
      tax_amount:0,
      discount: 0,
      shipping: 0,
      grand_total: calculateTotal(),
      received_amount: 0,
      paid_amount:0,
      payment_type: 0,
      payment_status: 0,
      status: 1,
      note: "ONLINE",
      reference_code: "REF",
      customer_name: name,
      customer_mobile: phoneNumber,
      customer_address: address,
      // Add other fields as needed
      // For line items, you can send cartItems directly
      line_items: cart.map(item => ({
        product_id: item.id,
        product_price: item.price,
        quantity: item.quantity,
        // Add other fields as needed
      }))
    };
    // 
    // Submit the data to the API
    api.post('/save', data)
    .then(response => {
      console.log('Sales data saved successfully:', response);
      const { id: salesId } = response.data; // Extract sales ID from the response
      localStorage.removeItem('cart'); // Clear the local storage cart
      history(`/success/${salesId}`); // Navigate to the success screen with sales ID

      
    })
    .catch(error => console.error('Error saving sales data:', error));
  };

  // Render the form and cart data
  return (
    <div>
    <Layout></Layout>
    <p className="font-weight-bold pl-2">Checkout</p>
    <div className="py-3 osahan-cart-item">
    <CartItem item={cart} />
    
    <div className="osahan-cart-item-profile bg-white shadow p-3">
  <div className="flex-column">
    <h6 className="font-weight-bold">Tell us about yourself for Delivery</h6>
  
    <form>
      <div className="form-group">
        <label htmlFor="exampleFormControlInput1" className="small font-weight-bold">Your Name</label>
        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Whitney G. Baily" value={name}
  onChange={(e) => setName(e.target.value)} />
      </div>
      {/* <div className="form-group">
        <label htmlFor="exampleFormControlInput2" className="small font-weight-bold">Email Address</label>
        <input type="email" className="form-control" id="exampleFormControlInput2" placeholder="youremail@gmail.com" />
      </div> */}
      <div className="form-group">
        <label htmlFor="exampleFormControlInput3" className="small font-weight-bold">Phone Number</label>
        <input type="number" className="form-control" id="exampleFormControlInput3" placeholder="1-800-643-4500"  value={phoneNumber}
  onChange={(e) => setPhoneNumber(e.target.value)}/>
      </div>
      <div className="form-group mb-0">
        <label htmlFor="exampleFormControlTextarea1" className="small font-weight-bold">Address</label>
        <textarea className="form-control" id="exampleFormControlTextarea1" placeholder="Full Address" rows={3} value={address}
  onChange={(e) => setAddress(e.target.value)} />
      </div>
      <div className="mt-4 mb-5">
<button className="btn btn-primary btn-block btn-lg" onClick={handleSubmit}>Place Order<i className="icofont-long-arrow-right"></i></button>
</div>
    </form>
    
  </div>
</div>

    </div>
    </div>
  );
  
};

// Connect the component to the Redux store and loadCart action
export default connect(null, { loadCart })(CheckoutPage);
