import React, { useState, useEffect } from 'react';
import MenuCategoryWithImages from './MenuCategoryWithImages';

const Menu = ({ addToCart }) => {
  const [menuData, setMenuData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch('https://dosapoint.goandroy.com/api/products/list');
        // const response = await fetch('http://limitless-pos.test/api/products/list');
        const data = await response.json();
        if (data.status && data.data) {
          setMenuData(data.data);
        } else {
          console.error('Error: No menu data found');
        }
      } catch (error) {
        console.error('Error fetching menu data:', error);
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };

    fetchMenuData();
  }, []);

  return (
    <div className='p-3'>
      {loading ? (
        <div className="text-center">Loading...</div>
      ) : (
        menuData.map((item, index) => (
          <MenuCategoryWithImages key={index} categoryTitle={item.product_name} items={[item]} addToCart={addToCart} />
        ))
      )}
    </div>
  );
};

export default Menu;
