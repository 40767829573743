import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/rootReducer'; // Import your root reducer
import {thunk} from 'redux-thunk';


const store = createStore(
  rootReducer, // Your root reducer
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
