import { combineReducers } from 'redux';
import cartReducer from './cartReducer'; // Import your cart reducer
// import otherReducer from './otherReducer'; // Import other reducers if you have them

const rootReducer = combineReducers({
  cart: cartReducer, // Assign your cart reducer to the 'cart' key in the Redux store state
  // Add other reducers here if needed
});

export default rootReducer;
