import React, { useEffect } from 'react';

import { BrowserRouter, Route, Routes,Link } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { loadCart } from './actions/cartActions';

import Cart from './Cart';
import CheckoutPage from './CheckoutPage';
import SuccessScreen from './SuccessScreen';
import Home from './Home';
function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCart());
  }, [dispatch]);

  return (
    <BrowserRouter>
      
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/menu" element={<Menu />} /> */}
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/success/:salesId" element={<SuccessScreen/>} />

        </Routes>
   
   
    </BrowserRouter >
  );
}



export default App;