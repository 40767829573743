import React, { useState } from 'react';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom'
import Layout from './Layout';
import Menu from './Components/Menu';
import CheckoutButton from './Components/CheckoutButton';

function Home() {
  const [cart, setCart] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);


  return (
    <div>
      <CheckoutButton />
      <Layout></Layout>
      <p className="font-weight-bold pl-2">Menu</p>
      <Menu />
      
    </div>
  );
}

export default Home;
