import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addToCart, removeFromCart } from '../actions/cartActions';

const MenuCategoryWithImages = ({ categoryTitle, items, addToCart, removeFromCart }) => {
  const [itemCounts, setItemCounts] = useState(Array(items.length).fill(0));
  const [cartLoaded, setCartLoaded] = useState(false); // New state to track if cart is loaded

  // Load cart from localStorage when component mounts
  useEffect(() => {
    if (!cartLoaded) {
      const savedCart = JSON.parse(localStorage.getItem('cart'));
      if (savedCart) {
        // Initialize item counts based on items in the saved cart
        const savedItemCounts = items.map(item => savedCart.find(cartItem => cartItem.id === item.id)?.quantity || 0);
        setItemCounts(savedItemCounts);
      }
      setCartLoaded(true); // Set cartLoaded to true after loading cart
    }
  }, [items, cartLoaded]); // Only run the effect when items or cartLoaded change

  const handleAddItemClick = (index) => {
    const newCounts = [...itemCounts];
    newCounts[index]++;
    setItemCounts(newCounts);
    addToCart(items[index]);
  };

  const handleRemoveItemClick = (index) => {
    if (itemCounts[index] > 0) {
      const newCounts = [...itemCounts];
      newCounts[index]--;
      setItemCounts(newCounts);
      removeFromCart(items[index]);
    }
  };

  return (
    
    <div className="row">
      
      {items.map((item, index) => (
        <div key={index} className="col-md-12 px-0 ">
          <div className="bg-white mb-4">
            <div className="p-3 border-bottom menu-list d-flex">
              <div className="media">
                <img src={item.photo} alt="" className="mr-3 rounded-pill" />
                <div className="media-body">
                  <h6 className="mb-1">{item.product_name} {item.badge && <span className={`badge ${item.badgeClass}`}>{item.badge}</span>}</h6>
                  <p className="text-muted mb-0">TSH {item.price}</p>
                </div>
              </div>
              {itemCounts[index] === 0 ? (
                <span className="ml-auto"><button  className="btn btn-outline-secondary btn-sm add-sm-btn" onClick={() => handleAddItemClick(index)}>ADD</button></span>
              ) : (
                <div className="osahan-quantity ml-auto">
                  <input type="button" value="-" className="minus" onClick={() => handleRemoveItemClick(index)} />
                  <input type="text" name="quantity" value={itemCounts[index]} title="Qty" className="qty" size="4" readOnly />
                  <input type="button" value="+" className="plus" onClick={() => handleAddItemClick(index)} />
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default connect(null, { addToCart, removeFromCart })(MenuCategoryWithImages);
