import { ADD_TO_CART, REMOVE_FROM_CART } from './cartActionTypes';
// cartActions.js
import store from '../store'; // Import the Redux store

// Action to load the cart from localStorage
export const loadCart = () => {
  return (dispatch) => {
    const cart = JSON.parse(localStorage.getItem('cart')) || [];
    dispatch({ type: 'LOAD_CART', payload: cart });
  };
};



// Action to add item to the cart
export const addToCart = (item) => {
    return (dispatch, getState) => {
      // Retrieve existing cart items from localStorage
      const existingCart = JSON.parse(localStorage.getItem('cart')) || [];
  
      // Check if the item already exists in the cart
      const existingItemIndex = existingCart.findIndex(cartItem => cartItem.id === item.id);
  
      if (existingItemIndex !== -1) {
        // If item already exists, update its quantity
        existingCart[existingItemIndex].quantity += 1;
      } else {
        // If item is not in the cart, add it with quantity 1
        existingCart.push({ ...item, quantity: 1 });
      }
  
      // Save the updated cart to localStorage
      localStorage.setItem('cart', JSON.stringify(existingCart));
  
      // Dispatch action to update Redux store
      dispatch({ type: 'ADD_TO_CART', payload: item });
    };
  };
  

//// Action to remove item from the cart
export const removeFromCart = (item) => {
    return (dispatch, getState) => {
      // Retrieve existing cart items from localStorage
      const existingCart = JSON.parse(localStorage.getItem('cart')) || [];
  
      // Find the index of the item in the cart
      const existingItemIndex = existingCart.findIndex(cartItem => cartItem.id === item.id);
  console.log(item.id)
  console.log(existingItemIndex)
      if (existingItemIndex !== -1) {
        // If item exists, decrease its quantity by 1
        existingCart[existingItemIndex].quantity -= 1;
        // If the quantity becomes 0, remove the item from the cart
        if (existingCart[existingItemIndex].quantity === 0) {
          existingCart.splice(existingItemIndex, 1);
        }
      }
  
      // Save the updated cart to localStorage
      localStorage.setItem('cart', JSON.stringify(existingCart));
  
      // Dispatch action to update Redux store
      dispatch({ type: 'REMOVE_FROM_CART', payload: item });
    };
  };
  