import React from 'react';

const CartItem = ({ item }) => {
  // Calculate item total
  const itemTotal = item.reduce((total, cartItem) => total + (cartItem.price * cartItem.quantity), 0);
  
  // Calculate to pay amount (assuming there's no discount, restaurant charges, or delivery fee)
  const toPay = itemTotal;

  return (
    <div>
      {/* Header */}
      <div className="gold-members d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
        <div className="media align-items-center">
          <div className="mr-2 text-danger">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
              <circle cx="8" cy="8" r="8"></circle>
            </svg>
          </div>
          <div className="media-body">
            <p className="m-0 font-weight-bold">Item</p>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="">
            <p className="text-gray mb-0 font-weight-bold mr-5">Quantity</p>
          </div>
          <p className="text-gray mb-0 font-weight-bold ml-2">Total</p>
        </div>
      </div>

      {/* Item details */}
      {item.map((cartItem, index) => (
        <div key={index} className="gold-members d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
          <div className="media align-items-center">
            <div className="mr-2 text-danger">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-circle-fill" viewBox="0 0 16 16">
                <circle cx="8" cy="8" r="8"></circle>
              </svg>
            </div>
            <div className="media-body">
              <p className="m-0" style={{ width: "150px" }}>{cartItem.product_name}</p> {/* Fixed width for product name */}
              <p className="m-0">{parseFloat(cartItem.price).toFixed(2)}</p> {/* Display price to 2 decimal places */}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end"> {/* Align quantity to the right */}
            <div className="">
              <p className="text-gray mb-0 mr-5">{cartItem.quantity}</p>
            </div>
            <p className="text-gray mb-0 ml-2 text "><span className="text-gray mb-0 ml-2 text-muted small">TSH</span> {(parseFloat(cartItem.price) * cartItem.quantity).toFixed(2)}</p> {/* Calculate and display subtotal */}
          </div>
        </div>
      ))}

      {/* Updated item total and other parameters */}
      <div className="shadow bg-white p-3 mb-3 clearfix">
        <p className="mb-1">Item Total <span className="float-right text-dark">TSH {itemTotal.toFixed(2)}</span></p>
        <p className="mb-1">Restaurant Charges <span className="float-right text-dark">TSH 0.00</span></p>
        {/* <p className="mb-1">Delivery Fee<span className="text-info ml-1"><i className="icofont-info-circle" /></span><span className="float-right text-dark">TSH 0.00</span></p> */}
        <p className="mb-1 text-success">Total Discount<span className="float-right text-success">TSH 0.00</span></p>
        <hr />
        <h6 className="font-weight-bold mb-0">TO PAY <span className=" small text-muted">(Estimated)</span> <span className="float-right">TSH {toPay.toFixed(2)}</span></h6>
      </div>
    </div>
  );
};

export default CartItem;
