// cartReducer.js

const initialState = {
    items: [], // Initialize items array to store cart items
  };
  
  const cartReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'ADD_TO_CART':
        // Check if the item is already in the cart
        const existingItemIndex = state.items.findIndex(item => item.id === action.payload.id);
  
        if (existingItemIndex !== -1) {
          // If item already exists, update its quantity
          const updatedItems = [...state.items];
          updatedItems[existingItemIndex].quantity += 1; // Increase quantity by 1
          return {
            ...state,
            items: updatedItems,
          };
        } else {
          // If item is not in the cart, add it with quantity 1
          return {
            ...state,
            items: [...state.items, { ...action.payload, quantity: 1 }], // Add item with quantity 1
          };
        }
      case 'REMOVE_FROM_CART':
        // Filter out the item to be removed based on its id
        const updatedItems = state.items.map(item => {
          if (item.id === action.payload.id) {
            // If item exists, decrease its quantity by 1
            item.quantity -= 1;
          }
          return item;
        }).filter(item => item.quantity > 0); // Remove items with quantity 0
        return {
          ...state,
          items: updatedItems,
        };
      default:
        return state;
    }
  };
  
  export default cartReducer;
  
  