import React from 'react';
import logo from "./dp-logo-300.png";

const Layout = () => {
  return (
    <div className="osahan-restaurant">
      <div className="osahan-restaurant-detail">
        <div className="p-3 osahan-inner-header bg-primary">
          <div className="d-flex align-items-center">
            <a className="font-weight-bold text-white text-back text-decoration-none d-flex" href="/">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="back-page bi bi-chevron-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path>
              </svg>
              <span className="pl-2">Home</span>
            </a>
            <div className="ml-auto d-flex align-items-center">
              <a className="text-white mx-2 top-nav-btn top-nav-btn-cart fs-18 position-relative" href="#ratings-and-reviews"><i className="feather-message-square"></i> <span className="border">3</span></a>
              <a className="text-white mx-2 top-nav-btn top-nav-btn-cart fs-18 position-relative" href="#ratings-and-reviews"><i className="feather-star"></i></a>
              <a className="text-white mx-2 top-nav-btn top-nav-btn-cart fs-18 position-relative" href="#ratings-and-reviews"><i className="feather-map-pin"></i></a>
              <a className="text-white mx-2 top-nav-btn top-nav-btn-cart fs-18 position-relative" href="contact-us.html"><i className="feather-phone"></i></a>
              <a className="toggle ml-2 text-white hc-nav-trigger hc-nav-1" href="#" role="button" aria-controls="hc-nav-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M2.5 11.5A.5.5 0 0 1 3 11h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 7h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4A.5.5 0 0 1 3 3h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="px-3 pb-3 bg-primary">
          <div>
          <img src={logo} alt="" height={100}/>
            <h2 className="font-weight-bold text-white">DosaPoint</h2>
            {/* <p className="font-weight-light text-white-50 m-0">963 Madyson Drive Suite 679</p> */}
            <div className="rating-wrap d-flex align-items-center mt-2">
              <ul className="rating-stars list-unstyled m-0">
                <li>
                  <i className="feather-star text-warning"></i>
                  <i className="feather-star text-warning"></i>
                  <i className="feather-star text-warning"></i>
                  <i className="feather-star text-warning"></i>
                  <i className="feather-star"></i>
                </li>
              </ul>
              <p className="label-rating text-white-50 ml-2 small m-0"> (245 Reviews)</p>
            </div>
          </div>
          <div className="pt-4">
            <div className="row">
              <div className="col-6">
                <p className="m-0 small text-white">Delivery <span className="badge badge-osahan badge-warning small">Free</span></p>
              </div>
              <div className="col-6">
                <p className="m-0 small text-white">Open time <span className="badge badge-osahan badge-dark small">8:00 AM</span></p>
              </div>
            </div>
          </div>
        </div>
        <div className="px-3 pt-3">
          <div className="d-flex item-aligns-center">
           
            {/* <a className="small text-primary font-weight-bold ml-auto" href="#">View all <i className="feather-chevrons-right"></i></a> */}
          </div>
          <div className="row">
            {/* Menu items */}
          </div>
          <div className="row">
            {/* Menu items */}
          </div>
          <div className="row">
            {/* Menu items */}
          </div>
        </div>
     
      </div>
    </div>
  );
};

export default Layout;
