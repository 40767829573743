import React from 'react';
import { useParams } from 'react-router-dom';
import success from "./success.gif";
const SuccessScreen = () => {
    const { salesId } = useParams(); // Get the sales ID from the URL parameters

    return (
        <div>
           <div className="vh-100 osahan-coming-soon p-4 d-flex justify-content-center align-items-center">
  <div className="osahan-text text-center">
    <div className="osahan-img pb-5">
      <img src={success} className="img-fluid mx-auto" alt="Responsive image" />
    </div>
    <h3 className="text-primary mb-3 font-weight-bold">Order Placed Sucessfully!</h3>
    <h4 className="text-secondary mb-3 font-weight-bold">Order Ref # {salesId}</h4>
    {/* <p className="lead small mb-0">Oops! Looks like you followed a bad link.</p>
    <p className="mb-5">If you think this is a problem with us, please <a href="#">tell us</a>.</p> */}
    <a href="/" className="btn btn-outline-primary">Home</a>
    {/* <a href="faq.html" className="btn btn-primary">Help</a> */}
  </div>
</div>

            {/* <h2>Order Placed Successfully!</h2>
            <p>Your order has been successfully placed with ID: {salesId}</p> */}
            {/* You can add more information or actions here */}
        </div>
    );
};

export default SuccessScreen;
